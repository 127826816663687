.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
    border-width: 2px;
}

html,
body {
    height: 100%;
	margin-top: 15px !important;
}

main {
    margin: 3rem 0 5rem;
}

.required-field-legend {
    font-size: 13px;
    font-style: italic;
}
.color-red {
    color: red;
}

.ui.basic.button:focus, .ui.basic.buttons .button:focus {
    box-shadow: 0 0 0 2px rgb(34 36 38 / 35%) inset, 0 0 0 0 rgb(34 36 38 / 15%) inset !important;
}
