.spacer {
    flex: 1 1 0;
}

.static-header {
    width: 100%;
    background-color: #022748;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 3px solid #1589ee;
}

.cobrand-header {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}

.static-header h1 {
    margin: 0;
    color: white;
    font-size: 16px;
}

.header-branding {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    flex-wrap: wrap;
    row-gap: 1.5rem;
}

.header-basic-branding {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.responsive-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .responsive-header .header-branding {
        justify-content: start;
        column-gap: 1rem;
    }
    .responsive-header {
        grid-template-columns: 1fr;
    }
    .language-wrapper {
        margin: 2rem 0 0;
    }
}
