.upper-footer .section-title {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    color: white !important;
    padding-bottom: 1rem;
}

.upper-footer {
    width: 100%;
    background-color: #022748;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: white;
    font-size: 16px;
}

.lower-footer {
    width: 100%;
    background: #F5F5F5;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.lower-footer a {
    color: black;
    cursor: pointer;
}

.upper-footer .info-link-group a {
    color: white;
    cursor: pointer;
}

.info-link-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.support-links {
    display: grid;
}

.support-link a {
    color: white;
    cursor: pointer;
}

.footer-link-container {
    display: flex; 
    justify-content: space-evenly;
}

@media only screen and (max-width: 991px) {
    .upper-footer .info-link-group:first-child {
    margin-bottom: 2rem;
    }
    .upper-footer .info-link-group p {
        margin: 0;
    }

    .upper-footer {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
.pl-2 {
    padding-left: 2rem !important;
}